import React, { ReactNode, useEffect, useState } from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { useRouter } from 'next/router';

export interface TabItems {
  [key: string]: { title: string; component: ReactNode };
}

interface TabsProps {
  tabs: TabItems;
}

export const Tabs: React.VFC<TabsProps> = ({ tabs }) => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState(
    (Array.isArray(router.query.tab) ? router.query.tab[0] : router.query.tab) ?? Object.keys(tabs)[0],
  );

  const setActiveTabInUrl = (tab: string) => {
    setActiveTab(tab);
    router.query.tab = tab;
    router.push(router);
  };

  useEffect(() => {
    setActiveTab((Array.isArray(router.query.tab) ? router.query.tab[0] : router.query.tab) ?? Object.keys(tabs)[0]);
  }, [router]);

  return (
    <RadixTabs.Root value={activeTab} onValueChange={setActiveTabInUrl} activationMode="manual">
      <AnimateSharedLayout>
        <RadixTabs.List className="flex p-6 pb-0 pl-0 ml-6 border-b border-gray-100 space-x-8">
          {Object.entries(tabs).map(([key, { title }]) => {
            const isActive = activeTab === key;
            return (
              <RadixTabs.Trigger
                key={key}
                value={key}
                className={`relative font-semibold hover:text-blue-500 ${
                  isActive ? 'text-blue-500' : 'text-gray-500'
                } pb-2 cursor-pointer`}
              >
                <span>{title}</span>
                {isActive && (
                  <motion.div
                    style={{ height: '1px' }}
                    className="w-full absolute bg-blue-500 bottom-0"
                    layoutId="navigation-tabs-underline"
                    transition={{ duration: 0.2 }}
                  />
                )}
              </RadixTabs.Trigger>
            );
          })}
        </RadixTabs.List>
      </AnimateSharedLayout>
      {Object.entries(tabs).map(([key, { component }]) => (
        <RadixTabs.Content key={key} value={key}>
          {component}
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
  );
};
