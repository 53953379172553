import React from 'react';
import { useSelect } from 'downshift';
import CaretDown from '../../assets/icons/CaretDown';
import Checked from '../../assets/icons/Checked';
import { FieldHookConfig, useField } from 'formik';
import clsx from 'clsx';

const defaultItemToString = (item: any): string => {
  if (item === null) {
    return '';
  }
  if (typeof item !== 'string' && typeof item !== 'number') {
    throw Error(`An item in a Select was not a string or a number but still used the defaultItemToString. This is not allowed, please supply a itemToString and itemToKey prop to the Combobox
item: ${JSON.stringify(item, null, 2)}`);
  }
  return item + ''; // convert number to string - does not affect strings
};

type SelectProps = FieldHookConfig<any> & {
  label?: string;
  items: any[];
  selected?: string;
  textNoSelected?: string;
  itemToString?: (item: any) => string;
};
export const Select: React.FC<SelectProps> = ({
  label,
  items,
  selected,
  textNoSelected = 'Selectioner une option...',
  disabled,
  itemToString = defaultItemToString,
  ...rest
}) => {
  const [, meta, helpers] = useField(rest);
  const { value } = meta;
  const { setValue } = helpers;

  const hasError = meta.touched && meta.error;

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    itemToString,
    onIsOpenChange: (state) => {
      if (state.isOpen === false) {
        helpers.setTouched(true);
      }
    },
    selectedItem: selected || value,
    onSelectedItemChange: ({ selectedItem }) => setValue(selectedItem),
  });

  return (
    <div
      className={clsx('inline-block w-full min-w-48 relative group', { 'pointer-events-none text-gray-500': disabled })}
    >
      <div className="inline-flex flex-col w-full">
        {label && (
          <label {...getLabelProps()} className="font-semibold pb-2">
            {label}
          </label>
        )}
        <button
          {...getToggleButtonProps()}
          disabled={disabled}
          type="button"
          className={clsx(
            'font-normal rounded-lg border-solid border py-3 px-4 inline-flex items-center justify-between',
            {
              'border-red-500 group-hover:border-red-500': hasError,
              'border-blue-500': isOpen && !hasError,
              'bg-gray-100 text-gray-500': disabled,
              'text-black': !disabled,
              'group-hover:border-blue-500 border-gray-300': !hasError,
            },
          )}
        >
          <span className="truncate flex-1 inline-flex">
            {selectedItem ? itemToString(selectedItem) : textNoSelected}
          </span>
          <CaretDown
            className={clsx('w-4 h-4', {
              'text-blue-500': isOpen && !hasError,
              'text-red-500': isOpen && hasError,
            })}
          />
        </button>
      </div>
      {isOpen && (
        <ul
          {...getMenuProps()}
          className="absolute w-80 z-50 shadow-select outline-none bg-white p-2 rounded-lg max-h-48 overflow-y-auto"
        >
          {isOpen &&
            items.map((item, index) => (
              <li
                className={clsx(
                  'p-2 rounded-lg mb-1 last:mb-0 select-none cursor-pointer inline-flex w-full align-middle justify-center items-center',
                  {
                    'bg-gray-100': highlightedIndex === index,
                    'text-blue-500': selectedItem === item,
                  },
                )}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                <span className="truncate flex-1">{itemToString(item)}</span>
                {selectedItem === item && <Checked className="w-4 h-4" />}
              </li>
            ))}
        </ul>
      )}
      {hasError ? <div className="font-normal text-sm text-red-500 mt-2">{meta.error}</div> : null}
    </div>
  );
};
